<template>
  <div :class="{ 'web-header': true, 'menu-open':menuShow, 'border-bottom': borderBottom }">
    <div class="web-header-wrap">
      <div class="web-header-wrap-logo">
        <a href="/">
          <img :src="$store.getters.getPcLogo" :title="$store.getters.getWebsiteName" :alt="$store.getters.getWebsiteName" />
        </a>
      </div>
      <div class="menu-btn">
        <i class="el-icon-menu" @click="menuShow = !menuShow" v-if="!menuShow"></i>
        <i class="el-icon-close" @click="menuShow = !menuShow" v-else></i>
      </div>
      <div :class="{ 'web-header-wrap-menu': true, 'open': menuShow }">
        <nav :class="['nav-menu', { 'mobile': isMobile }]">
          <ul>
            <li v-for="item in menuList" :key="item.title">
              <span :class="{ 'active': activeUrl == item.url }" @click="jumpPage(item)">{{ item.title }}</span>
              <ul v-if="item.children && item.children.length" :class="{ 'show': item.showSubmenu }">
                <li v-for="child in item.children" :key="child.title">
                  <span :class="{ 'active': levelUrl == child.url }" @click="jumpPage(child)">
                    {{ child.title }}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div class="web-header-wrap-menu-custom">
          <div class="web-header-wrap-menu-custom-search">
            <el-input
                placeholder="请输入要关注的信息"
                v-model="keywords"
                @keyup.native.enter="searchToPage">
              <el-button slot="append" icon="el-icon-search" @click="searchToPage"></el-button>
            </el-input>
          </div>
<!--          <div class="web-header-wrap-menu-custom-user">-->
<!--            <i class="el-icon-s-custom"></i>-->
<!--          </div>-->
          <a href="https://40rencmsenapi.muyiapp.com/" target="_blank" class="web-header-wrap-menu-custom-language">
            En
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navigationList, classifyList } from "@/api";
export default {
  name: "WebHeader",
  props: {
    // 当前主菜单的地址
    activeUrl: {
      type: String,
      default: ""
    },
    // 当前子菜单的地址
    levelUrl: {
      type: String,
      default: ""
    },
    // 是否显示下边框
    borderBottom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: "",
      menuList: [],
      isMobile: false,
      // 是否显示菜单
      menuShow: false,
      // 搜索的关键词
      keywords: "",
    }
  },
  created() {
    this.getMenuList();
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    showSubmenu(item) {
      this.$set(item, 'showSubmenu', true);
    },
    hideSubmenu(item) {
      this.$set(item, 'showSubmenu', false);
    },
    // 获取菜单列表
    async getMenuList() {
      let param = {
        pageSize: 50,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "asc",
        search: "menu_type:true|status:1",
        searchFields: "menu_type:=|status:="
      };
      let res = await navigationList(param);
      if (res && res.code == 200) {
        this.handleData(res.data.list);
      }
    },
    // 数据处理
    async handleData(rows) {
      if (rows && rows.length > 0) {
        let classParam = {
          pageSize: 50,
          currentPage: 1,
          orderBy: "sort",
          sortedBy: "asc",
          search: "is_show:true",
          searchFields: "is_show:="
        };
        let classRes = await classifyList(classParam);
        for (let i = 0; i < rows.length; i++) {
          let item = rows[i];
          if (item.nav_type == '1') {
            // 关联分类
            if (classRes.code == 200 && classRes.data && classRes.data.list && classRes.data.list.length > 0) {
              let temp = classRes.data.list.find(jtem => jtem.id === item.page_id);
              item['url'] = temp.list_template;
            }
          } else if (item.nav_type == '2') {
            // 单页
            item['url'] = `/detail/${item.page_id}`;
          } else if (item.nav_type == '3') {
            // 外联
            item['url'] = item.link;
          } else if (item.nav_type == '0') {
            if (item.page_id == 1) {
              item['url'] = "/";
            } else if (item.page_id == 9999) {
              item['url'] = "/achievement?cid=9999";
            }
          }
        }
        this.menuList = this.handleTree(rows, "id", "parent_id");
      }
    },
    // 跳转到页面
    jumpPage(row) {
      if (row && (!row.children || row.children.length == 0)) {
        this.routerLink(row.url, row.target);
      }
    },
    // 搜索页面
    searchToPage() {
      if (this.keywords && this.keywords.length > 0) {
        this.routerLink("/search?keyword=" + encodeURIComponent(this.keywords), true);
      } else {
        this.$message.warning("请输入关键词");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.web-header {
  width: 100%;
  height: 136px;
  position: relative;
  background-color: #ffffff;
  &.border-bottom {
    border-bottom: 1px solid #cccccc;
  }
  &-wrap {
    position: relative;
    height: 100%;
    padding: 0 10%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-logo {
      a {
        line-height: 0;
        display: inline-block;
      }
    }
    .menu-btn {
      display: none;
    }
    &-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav-menu ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
        color: #0b0b0b;
      }
      .nav-menu > ul > li {
        display: inline-block;
        position: relative;
        padding: 0 20px;
        height: 136px;
        line-height: 136px;
      }
      .nav-menu > ul > li > span {
        position: relative;
        display: block;
        cursor: pointer;
        &:hover, &.active {
          font-family: "HarmonyOS_Bold";
          &:after {
            position: absolute;
            content: '';
            width: 100%;
            bottom: 0;
            left: 0;
            border-bottom: 4px solid #ed6f33;
          }
        }
      }
      .nav-menu > ul > li > ul {
        display: none;
        position: absolute;
        top: 136px;
        padding: 20px 10px;
        left: -10px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        width: 100%;
        z-index: 99999;
      }
      .nav-menu > ul > li {
        &:hover {
          ul {
            display: block;
          }
        }
      }
      .nav-menu > ul > li > ul > li {
        display: block;
        padding: 15px 0;
      }
      .nav-menu > ul > li > ul > li > span {
        display: block;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        &:hover, &.active {
          font-family: "HarmonyOS_Bold";
        }
      }
      &-custom {
        margin-top: 8px;
        margin-left: 30px;
        display: flex;
        align-items: center;
        &-search {
          ::v-deep {
            .el-input__inner:focus {
              border-color: #ed6f33;
            }
          }
        }
        &-user, &-language {
          min-width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
          border: 1px solid #ececec;
          background-color: #f7f7f7;
          user-select: none;
          &:hover {
            color: #ffffff;
            background-color: #ed6f33;
          }
        }
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .web-header {
    height: 130px;
    &-wrap {
      padding: 0 30px;
      &-logo {
        max-width: 230px;
      }
      &-menu {
        .nav-menu ul {
          font-size: 14px;
        }
        .nav-menu > ul > li {
          height: 130px;
          padding: 0 15px;
          line-height: 130px;
        }
        .nav-menu > ul > li > ul {
          top: 130px;
        }
        &-custom {
          margin-left: 15px;
          max-width: 240px;
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-header {
    height: auto;
    &.menu-open {
      position: fixed;
      top: 0;
      z-index: 9999;
    }
    &-wrap {
      padding: 10px 40px;
      .menu-btn {
        display: block;
        i {
          font-size: 36px;
        }
      }
      &-menu {
        position: fixed;
        width: 100%;
        height: calc(100vh - 79px);
        left: 0;
        top: 79px;
        margin: 0;
        background-color: #ffffff;
        border-top: 1px solid #333;
        z-index: 99;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
        display: none;
        &.open {
          display: block;
        }
        .nav-menu {
          margin-top: 45px;
        }
        .nav-menu > ul > li {
          width: 100%;
          height: auto;
          line-height: normal;
          padding-top: 30px;
          span {
            &:hover, &.active {
              &:after {
                display: none;
              }
            }
          }
        }
        .nav-menu > ul > li > ul {
          position: relative;
          top: 0;
          left: 0;
          box-shadow: none;
        }
        .nav-menu > ul > li > ul.show {
          padding: 0 20px;
        }
        .nav-menu > ul > li > ul > li {
          padding: 0;
          margin-top: 15px;
        }
        .nav-menu > ul > li > ul > li > span {
          text-align: left;
          padding-left: 20px;
        }
        &-custom {
          position: absolute;
          width: calc(100% - 40px);
          top: 20px;
          left: 20px;
          margin: 0;
          &-search {
            flex: 1;
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-header {
    height: auto;
    &.menu-open {
      position: fixed;
      top: 0;
      z-index: 9999;
    }
    &-wrap {
      padding: 15px 20px;
      .menu-btn {
        display: block;
        i {
          font-size: 28px;
        }
      }
      &-logo {
        width: 200px;
      }
      &-menu {
        position: fixed;
        width: 100%;
        height: calc(100vh - 77px);
        left: 0;
        top: 77px;
        margin: 0;
        background-color: #ffffff;
        border-top: 1px solid #333;
        z-index: 99;
        overflow-y: auto;
        scrollbar-width: none;
        overflow-x: hidden;
        scrollbar-color: transparent transparent;
        display: none;
        &.open {
          display: block;
        }
        .nav-menu {
          margin-top: 45px;
        }
        .nav-menu > ul > li {
          width: 100%;
          height: auto;
          line-height: normal;
          padding-top: 30px;
          span {
            &:hover, &.active {
              &:after {
                display: none;
              }
            }
          }
        }
        .nav-menu > ul > li > ul {
          position: relative;
          top: 0;
          left: 0;
          box-shadow: none;
        }
        .nav-menu > ul > li > ul.show {
          padding: 0 20px;
        }
        .nav-menu > ul > li > ul > li {
          padding: 0;
          margin-top: 15px;
        }
        .nav-menu > ul > li > ul > li > span {
          text-align: left;
          padding-left: 20px;
        }
        &-custom {
          position: absolute;
          width: calc(100% - 40px);
          top: 20px;
          left: 20px;
          margin: 0;
          &-search {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>